@media screen and (max-width: 768px) {
  .ant-pagination-total-text {
    display: block;
    margin-bottom: 0.5rem;
  }
  .custom-button-group {
    display: none;
  }

  .slide-btn {
    margin: 10px;
    width: 30px;
    height: 30px;
    background: #e4e3e5 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border-radius: 50%;
    border: 0;
    /* transition: all .3s; */
    transition: ease-out all 0.3s;
    color: #292d32;
  }

  .parent {
    width: calc(100% - 1rem);
    border-radius: 15px;
    /* width: 100%; */
    height: 121px !important;
  }
  .parent .div {
    display: flex;
    flex-direction: column;
    color: white;
    position: relative;
    bottom: 1.2rem !important;
    left: 0.1rem !important;
    /* font-size: 48px; */
    line-height: 58px;
    font-weight: bold;
    position: absolute;
    font-family: proxima-nova;
    padding: 0 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .custom-button-group {
    text-align: center;
    position: absolute;
    top: 18.5%;
    right: 5%;
  }
  .slide-btn {
    margin: 10px 1rem;
    width: 60px;
    height: 60px;
    background: #e4e3e5 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border-radius: 50%;
    border: 0;
    /* transition: all .3s; */
    transition: ease-out all 0.3s;
    color: #292d32;
  }
}

@media only screen and (max-width: 991px){
 	.box-banner-details{	   		
    display: none;
  }
}
